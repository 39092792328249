import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function Directorios2() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div className="w-full h-auto flex flex-col items-center justify-center py-3">
            <div className="flex justify-center items-center pb-5 mt-5">
                {
                    rpdata?.gmb?.link.length > 1 ?
                        <a href={rpdata?.gmb?.link} target="_blank" rel='noopener noreferrer'>
                            <img src={rpdata?.gmb?.img} alt="Not found" width={'250px'} />
                        </a>
                        : null
                }
            </div>
            <div className="w-full md:w-[20%] h-auto text-center">
                <h2 className="text-[40px] colorTextpl">Follow Us:</h2>
            </div>
            <div className="w-4/5 flex md:flex-row flex-col justify-center items-center">
                <div className="w-[100%] h-auto py-2 px-4 bg-white rounded-lg">
                    <div className="w-full h-auto">
                        <Swiper
                            // install Swiper modules
                            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                            spaceBetween={10}
                            slidesPerView={3}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 10
                                },
                                480: {
                                    slidesPerView: 1,
                                    spaceBetween: 10
                                },
                                640: {
                                    slidesPerView: 4,
                                    spaceBetween: 5
                                }
                            }}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}

                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                        >
                            {
                                rpdata?.dbSocialMedia?.directorios.map((item, index) => {
                                    return (
                                        <SwiperSlide key={index} className="w-[60px] h-[120px] flex justify-center items-center px-2 py-10">
                                            <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                                <img src={item.image} alt="Not Found" width={'250px'} />
                                            </a>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                    <div className="w-full h-auto">
                        <Swiper
                            // install Swiper modules
                            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                            spaceBetween={10}
                            slidesPerView={3}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 10
                                },
                                480: {
                                    slidesPerView: 1,
                                    spaceBetween: 10
                                },
                                640: {
                                    slidesPerView: 4,
                                    spaceBetween: 5
                                }
                            }}
                            autoplay={{
                                delay: 7000,
                                disableOnInteraction: false,
                            }}

                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                        >
                            {
                                rpdata?.dbSocialMedia?.redes.map((item, index) => {
                                    return (
                                        <SwiperSlide key={index} className="w-[30px] h-[120px] flex justify-center items-center px-3">
                                            <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                                <img src={item.image} alt="Not Found" width={'250px'} />
                                            </a>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Directorios2